import { CSS_HELPERS, THEME_COLORS, MEDIA_SCREENS } from '@theme/constants';
import styled from 'styled-components';
import { Typography, Button, Row, Col } from 'antd';
import Link from 'next/link';
import Bolder from '@components/common/Bolder';

const { MD, LG } = MEDIA_SCREENS;
const { Title } = Typography;

const BoxCard = ({
    titulo,
    descripcion_corta,
    hideMobile,
    acciones
}) => {
    return (
        <Row align="top">
            <Col
                xs={{ offset: null, span: hideMobile ? 0 : 24 }}
                sm={{ offset: null, span: hideMobile ? 0 : 24 }}
                md={{ offset: null, span: hideMobile ? 0 : 24 }}
                lg={{ offset: 13, span: hideMobile ? 11 : 0 }}
                xl={{ offset: 14, span: hideMobile ? 10 : 0 }}
            >
                <Card>
                    <div className="gp1">
                        <Title level={1}><Bolder titulo={titulo} /></Title>
                        <p className="md"> {descripcion_corta}</p>
                    </div>
                    <div className="gp2">
                        {
                            acciones.map(({ titulo, url, id }) => (
                                <Link href={url} key={id} >
                                    <Button className="btn_custom_secondary">
                                        {titulo}
                                    </Button>
                                </Link>
                            ))
                        }
                    </div>
                </Card>
            </Col>
        </Row>
    );
}



export default BoxCard


const Card = styled.div`  
    border-radius: 2.1em;

    @media (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.MD}px) { 
        margin-top:-1.5em;
        border-radius: 2.1em 2.1em 0 0;
        min-height:21em ;  
        .gp1{padding: 1em;}
        .gp2{padding:1em 1em 4.2em 1em; background:white;}


    }
    
    @media (min-width: ${MD}px) and (max-width: ${MEDIA_SCREENS.LG}px) { 
        margin:3em 0; 
        min-height:15em;
        width:100% ;
        .gp1{padding: 3em 2em;}
        .gp2{padding:1em; background:white;}
    }
    
    @media (min-width: ${LG}px) {
        margin:3em 0; 
        min-height:21em ;
        .gp1{padding: 3em 2em;}
        .gp2{padding:1em; background:white;}
    }


    ${CSS_HELPERS.BOX_SHADOW};
    display: flex;
    flex-direction: column;
    transition: all .4s ease; 
    justify-content: space-between;
    overflow: hidden;
    h1,h2,h3, h4{
        color: ${THEME_COLORS.light.primary.contrastText} !important;
        text-transform: uppercase;
        font-family: 'Georgia','Times New Roman','Times','serif';
        margin-top:0;
 
        font-size:2.2em; 
    }
    p{
        color: ${THEME_COLORS.light.primary.contrastText} !important;
    }
    background: linear-gradient(179.71deg, #498CC9 57.61%, #498CC9 76.77%);
  
`

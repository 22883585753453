"use client";

import IconSwitcher from '@components/common/IconSwitcher'
import LineBreaker from '@components/common/LineBreaker'
import React from 'react'
import styled from 'styled-components'

const CaracteristicaItem = ({
    titulo,
    invert,
    icon
}) => {

    return (
        <View invert={invert}>
            <IconSwitcher name={icon} size={6} />
            <span className='uppa strong600 sm'>
                <LineBreaker titulo={titulo} bold />
            </span>
        </View>
    )
}

export default CaracteristicaItem;


const View = styled.div`
    
    display: flex;
    flex-direction: ${props => props.invert ? 'row-reverse' : 'row'};
    align-items: center;
    gap:1em;
    svg{
        /* height:6em;
        width:6em; */
    }
`
import { CSS_HELPERS, THEME_COLORS } from '@theme/constants';
import React from 'react';
import styled from 'styled-components';

const Muesca = () => {
    return (

        <View>
                <Line></Line>
                {/* <Mask></Mask> */}
                <Line></Line>
        </View>

    )
};

export default Muesca;

const View = styled.div`
    display:flex;
    position: absolute;
    bottom:0;
    width:100%;
    height: 1em;
    overflow: hidden;

`;

const Line= styled.div`
    background-color: ${THEME_COLORS.light.secondary.main};
    width: 100%;
`

const Mask = styled.div`


    background: radial-gradient(circle 6em at top, #0000 100%,  ${THEME_COLORS.light.secondary.main});    
    margin-top: -5.4em;
    width: 100%;

    
`

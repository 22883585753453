"use client";

import { MEDIA_SCREENS } from '@theme/constants'
import { Col, Row } from 'antd';
import AccesoDirectoCard from "./AccesoDirectoCard";
import _ from "lodash";
import Link from "next/link";
import { useRouter, usePathname } from "next/navigation";


const AccesosDirectos = ({
    data,
    preSlug
}) => {
    //const { asPath } = useRouter();
    const pathname = usePathname();
    const subcategorias = _.orderBy(data, item => item.orden);

    const path = preSlug ? `${preSlug}/` : pathname;
    return (
        <Row gutter={[24, 24]} style={{ height: '100%' }}   >
            {subcategorias.map(item => {
                return (
                    <Col xs={24} sm={12} md={12} key={item.id}>
                        <Link href={`${path}${item.slug}`}>
                            <AccesoDirectoCard {...item}  > </AccesoDirectoCard>
                        </Link>
                    </Col>)
            })}
        </Row>
    )
}

export default AccesosDirectos




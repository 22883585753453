import Bolder from '@components/common/Bolder';
import IconSwitcher from '@components/common/IconSwitcher';
import { CSS_FONTS, CSS_HELPERS, MEDIA_SCREENS, THEME_COLORS } from '@theme/constants';
import { Col, Row, Typography } from 'antd';
import Image from 'next/legacy/image';
import React from 'react'
import styled from 'styled-components';

const SubCategoriaCard = ({
    id,
    acceso_directo,
    orden,
    slug,
    titulo,
    carnada,
    descripcion_corta,
    descripcion_larga,
    vista,
    createdAt,
    updatedAst,
    entrada,
    caracteristicas,
    acciones,
    detalles,
    entradas,
    figuraId,
    iconoId
}) => {

    const imageUrl = figuraId
        ? `/figuras/${iconoId}.svg`
        : '/img/imagen1.jpg';

    const icon = caracteristicas
        && caracteristicas.length
        && caracteristicas[0]
        && caracteristicas[0].icon || ''



    return (

        <View>
            <Row gutter={[15, 15]}>
                <Col xs={4}>
                    <div className="image_box">
                        <Image
                            src={imageUrl}
                            width={45}
                            height={45}
                            layout="responsive"
                            alt={titulo}
                            unoptimized={true}
                        />
                    </div>
                </Col>
                <Col xs={20}>
                    <Row align="middle" style={{ height: '100%' }}>
                        <p className="light_primary_main  strong400">
                            <Bolder titulo={titulo} bold={false} />
                        </p>
                    </Row>

                </Col>
            </Row>

        </View>
    )
}

export default SubCategoriaCard;

const View = styled.div`
    border: .1em solid ${THEME_COLORS.light.secondary.main};
    border-radius: 0 30pt 30pt 30pt;
    overflow: hidden;
    padding:.6em;
    .image_box {
        img {
            border-radius: 50%;
            height: 6em;
            width: 6em;
            object-fit: cover;
           
        };
        svg{
            fill:white;
        }
    }


`
import { THEME_COLORS } from '@theme/constants';
import styled from 'styled-components';
import Link from 'next/link';
import { useRouter, usePathname } from 'next/navigation';
const ContactoCard = ({ slug, titulo }) => {
    const asPath = usePathname();
    return (
        <View>
            <Link href={`${asPath}${slug}`} className='sm'>
                {titulo}
            </Link>
        </View>
    )
}

export default ContactoCard;


const View = styled.div`
        width:100%;

    a {
        height:3.3em ;
        width:100%;
        background:white;
        border: 1.8pt solid ${THEME_COLORS.light.secondary.main};
        border-radius:0 12pt 12pt 12pt ;

        display:flex ;
        justify-content:center;
        align-items: center;
        color:${THEME_COLORS.light.primary.main};
    }
`


/* eslint-disable react-hooks/exhaustive-deps */
import { THEME_COLORS } from '@theme/constants'
import { Button, Row, Col } from 'antd'
import _ from 'lodash'
import Link from 'next/link'
import { useRouter, usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const AccionList = ({ data, setShowDisplay, slug }) => {
	//const { asPath } = useRouter();
	const pathname = usePathname();

	const _data = data && data.length ?
		_.orderBy(data, 'orden', 'desc') : [{
			url: pathname + slug,
			titulo: 'Saber más',
		}];


	const [datos, setDatos] = useState()
	useEffect(() => {
		if (_data && data.length) {
			const _datos = _data.filter(item => item.titulo !== 'Simulador')
			setDatos(_datos)
		}
	}, [data]);



	return (
		<View
			onMouseEnter={() => setShowDisplay(false)} onMouseLeave={() => setShowDisplay(true)}
		>
			<div className='action_list'>
				<Row gutter={[15, 15]}>
					{datos?.map((item, index) => {
						const buttonType = index % 2 ? "primary" : "link";
						const className = !(index % 2) ? "btn_custom_secondary" : "";

						return (
							<Col xs={24} key={item.id}>
								<Button type={buttonType} className={className} size="large" block>
									<Link href={item.url} rel="noopener noreferrer">
										{item.titulo}
									</Link>
								</Button>
							</Col>
						)
					})}
				</Row>
			</div>
		</View>

	)
}

export default AccionList;


const View = styled.div`
    z-index:8 ;
    position:absolute ;
    width:100%;
    height:100%;
    bottom:0;
    display:flex ;
    flex-direction:column ;
    justify-content:flex-end;

    .action_list{
        opacity:0;
    }

    :hover, :active{ 
        .action_list{
            z-index:9 ;
            opacity:1;
            will-change: transform;
            transition: .3s ease;
            background:${THEME_COLORS.dark.bg.main}54;
            border-radius:9pt ;
            height:100% ;

            display:flex ;
            flex-direction:column ;
            justify-content:center;
            padding: 1em;
        }
    } 
  
`



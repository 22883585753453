"use client";

import IconSwitcher from '@components/common/IconSwitcher';
import { CSS_FONTS, CSS_HELPERS, MEDIA_SCREENS, THEME_COLORS } from '@theme/constants';
import { Button, Col, Row, Typography } from 'antd';
import React, { useState } from 'react'
import styled from 'styled-components';
import AccionList from './acciones/AccionList';
import Bolder from '@components/common/Bolder';
import { handleFiguras } from 'utils/fileUtils';
import Image from 'next/legacy/image';


const SubCategoriaCard = ({
	id,
	orden,
	slug,
	titulo,
	carnada,
	vista,
	entrada,
	caracteristicas,
	acciones,
	figuraId,
	scale
}) => {

	const imageUrl = handleFiguras(figuraId);

	const icon = caracteristicas
		&& caracteristicas.length
		&& caracteristicas[0]
		&& caracteristicas[0].icon || ''

	let tituloParts = titulo && titulo.split(' ');
	tituloParts = tituloParts.filter(item => item.length > 0)
	const lastPart = tituloParts.splice(tituloParts.length - 1, 1);

	const [showDisplay, setShowDisplay] = useState(true);

	// console.log({
	// 	slug,
	// 	titulo,
	// 	carnada,
	// 	vista,
	// 	entrada,
	// 	caracteristicas,
	// 	acciones,
	// 	figuraId,
	// 	scale
	// });

	return (
		<View scale={scale} hasIcon={entrada?.slug === "inversion"} showDisplay={showDisplay}>

			{/* <img
				src={`figuras/${figuraId}.webp`}
				alt="Categoria"
			/> */}
			<Image
				alt="Categoria"
				src={`/figuras/${figuraId}.webp`}
				layout="responsive"
				objectFit="cover"
				quality={100}
				unoptimized={true}
			/>
			<div className="bottom">

				{icon ? <div className="hasIconClass">
					<IconSwitcher name={icon} />
				</div> : <></>}

				{
					(vista === "servicios") ?
						<>
							{
								tituloParts.length ? (
									<p className='sm uppa light_primary_contrastText mt-4'>
										{tituloParts.map((item, index) => (<span style={{ fontSize: 12 }} key={item + index}>{item} </span>))}
									</p>) : <></>
							}
							<Typography.Title level={1} style={{ marginTop: 0 }}>
								<Bolder titulo={lastPart[0]} bold={false} boldColor={'light_primary_contrastText'} color={'light_primary_contrastText'} />
							</Typography.Title>
						</>
						:
						<Typography.Title level={4}>
							<span className='light_primary_contrastText'>
								<Bolder titulo={titulo} bold={false} />
							</span>
						</Typography.Title>
				}
			</div>




			<AccionList
				data={acciones}
				setShowDisplay={setShowDisplay}
				slug={slug}
			/>
		</View>
	)
}

export default SubCategoriaCard;

const View = styled.div`
	${CSS_HELPERS.ROUNDED};
	position: relative;
	display: flex;
	flex-direction: column;
	background:${THEME_COLORS.light.primary.main};
	height:400px;
	@media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.XS}px) { 
	height:500px;
	width:100%;

	}


	img {
		transition: .3s ease;
		width: 100%;
		object-fit: cover;  
		height:${props => !props.showDisplay ? 400 : 400 / 3 * 2}px;

		@media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.XS}px) { 
			height:${props => !props.showDisplay ? 600 : 600 / 3 * 2}px;
		}
	}

	.bottom {
		${props => props.hasIcon ? `
			// padding:1em;
			align-items:center ;
			justify-content:flex-end ;
		`: ""}
		height:${400 / 3 * 1}px;         
		background:${THEME_COLORS.light.primary.main};
		border-top: 6pt solid ${THEME_COLORS.light.secondary.main};
		position:absolute;
		width:100%;
		bottom:0;
		display:flex;
		justify-content:center;
		flex-direction:column ; 
		padding-top:${props => props.hasIcon ? 3 : 0}em;
		transform: translateY(${props => !props.showDisplay ? 30 : 0}em); /* Equivalente a translateX(10px) */



		h4.ant-typography{
			padding:0;
			margin:0;
			font-size:1.2em;
		}

		.hasIconClass{
			position:absolute;
			top:-3.6em;
			/* width:100%; */
			svg{
				font-size:2.3em;
			}
			
		}

	}



	.ant-typography{
		font-family: 'Georgia','Times New Roman','Times','serif';
		font-weight:400;
		text-transform:uppercase ;
		text-align:center ;
	}
	.card-height{
		height: ${props => props.scale ? 390 * props.scale : 390}px;
		overflow: hidden;
	}

  
    



`;


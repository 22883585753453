"use client";

import { MEDIA_SCREENS } from '@theme/constants'
import { Col, Row } from 'antd';
import SubCategoriaCard from "./SubCategoriaCard";
import ContactoCard from './ContactoCard'
import { usePathname } from "next/navigation";
import _ from "lodash";
import styled from "styled-components";


const Subcategorias = ({ data }) => {

    const asPath = usePathname();
    return (
        <>

            {(asPath?.includes('contactanos')) ?
                <Row gutter={[16, 16]}>
                    {_.orderBy(data, 'titulo').map(item => <Col key={item.id}
                        xs={12}
                        sm={12}
                        md={6}>
                        <ContactoCard key={item.id} {...item} /></Col>)}
                </Row>
                : <View isHome={asPath === '/'}>
                    {_.orderBy(data, 'orden').map((item) =>
                        <div className='bx_content' key={item.id} >
                            <SubCategoriaCard key={item.id} {...item} />
                        </div>
                    )}
                </View>

            }
        </>
    )




}

export default Subcategorias;

const View = styled.div`
    /* width:100%;
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    gap: 1em; */
    
    display: grid;
    grid-template-columns: ${props => props.isHome ? 'repeat(5,1fr)' : 'repeat(3,1fr)'};

    @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.MD}px) {
        grid-template-columns: 1fr;
    }
    
    gap: 1em;

    width: ${props => props.isHome ? '100%' : '65%'};
    margin:0 auto;


`;


const GridItem = styled.div`
    @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.XS}px) { 
        width:100%;
    }
    @media screen and  (min-width: ${MEDIA_SCREENS.XS}px) and (max-width: ${MEDIA_SCREENS.SM}px) { 
        width:50%;
    }
    @media screen and  (min-width: ${MEDIA_SCREENS.SM}px) and (max-width: ${MEDIA_SCREENS.MD}px) { 
        width:25%;
    }
    @media screen and  (min-width: ${MEDIA_SCREENS.MD}px) and (max-width: ${MEDIA_SCREENS.LG}px) { 
        width:20%;
    };
    @media screen and  (min-width: ${MEDIA_SCREENS.LG}px)  { 
        width:20%;
    }
    padding:.6em;
`

"use client";

import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { THEME_COLORS, CSS_HELPERS } from '@theme/constants';
import CalificacionIcon from './CalificacionIcon';

const Trayectoria = ({ titulo, descripcion }) => {
    return (
        <View>
            <Row>
                <Col xs={24} md={12} className="side">
                    <Row align='middle' >
                        <Col xs={24} md={24} lg={15} xl={12}>
                            <Typography.Title style={{
                                // fontSize: '2.5rem',
                                marginTop: 0,
                            }} level={1} >
                                Nuestro <br />
                                Compromiso, <br />
                                la <strong> Excelencia.</strong>
                            </Typography.Title>
                        </Col>
                        <Col xs={24} md={24} lg={9} xl={12}>
                            <CalificacionIcon />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={12} className="aside">
                    <Row>
                        <Col xs={24} className="aside_group">
                            <p>
                                <span>Todos nuestros Fondos de Inversión se distinguen </span>
                                <br />
                                <span>con la </span>
                                <span className="strong">máxima Calificación de Riesgo</span>
                            </p>
                        </Col>
                        <Col xs={24} className="bar"> </Col>
                        <Col xs={24} className="aside_group">
                            <p>
                                <span>Con la experiencia de </span>
                                <span className="strong">30 años </span>
                                <span>para responder eficientemente a más de </span>
                                <span className="strong">353.000 clientes, </span>
                                <span>que han confiado en nosotros. </span>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </View>
    )
}

export default Trayectoria;

const View = styled.section`
  ${CSS_HELPERS.ROUNDED};
  ${CSS_HELPERS.BOX_SHADOW}
  h1.ant-typography{
    font-family: 'Georgia','Times New Roman','Times','serif';
    font-size:2.5em ;
  }

  .side{
      background-color: ${THEME_COLORS.light.primary.alt};
      display:flex ;
      flex-direction:column ;
      justify-content:center;
      padding:2.4em;
      h1,h2,h3,h4,h5,h6{
        color:${THEME_COLORS.light.primary.contrastText};
        font-weight: 500;
      }
  }
  .aside{
    background-color: ${THEME_COLORS.light.bg.main};
    text-align: center;
    .aside_group{
        padding: 2em;
    }
    .bar{
        width: 100%;
        height: .3em;
        background-color: ${THEME_COLORS.light.line.main};
    }
  }
`

import React from 'react'
import styled from 'styled-components';
import { CSS_HELPERS, MEDIA_SCREENS, THEME_COLORS } from '@theme/constants';
import { Col, Typography } from 'antd';
import BoxCard from './BoxCard';
import Muesca from './Muesca';
const { XS, SM, MD, LG } = MEDIA_SCREENS;
import Image from 'next/legacy/image'
import BoxUi from '@components/common/BoxUi';


const CarouselItem = ({
    id,
    titulo,
    descripcion_corta,
    descripcion_larga,
    figuraId,
    vista,
    caracteristicas,
    acciones,
}) => {

    // const imageUrl = figuraId
    //     //? `${process.env.API_URL}/figuras/${figuraId}`
    //     ? `/figuras/${figuraId}.webp`
    //     : '/img/imagen1.jpg';

    return (
        <MediaContainer>
            <Image
                alt={descripcion_corta}
                src={`figuras/${figuraId}.webp`}
                layout="responsive"
                objectFit="cover"
                width={1200 / 2}
                height={450 / 2}
                quality={100}
                unoptimized={true}
            />

            <Col xs={0} lg={24} className="boxCard">
                <BoxUi transparent>
                    <BoxCard
                        titulo={titulo}
                        descripcion_corta={descripcion_corta}
                        acciones={acciones}
                        hideMobile

                    />
                </BoxUi>

            </Col>

            <Col xs={24} lg={0}>
                <BoxCard
                    titulo={titulo}
                    descripcion_corta={descripcion_corta}
                    acciones={acciones}
                />
            </Col>

        </MediaContainer>
    )
}

export default CarouselItem;



const MediaContainer = styled.div`
    position:relative ;
    height:100% ;
    img {
        @media (min-width: ${MD}px) { 
            width: 100%;
            height: 608px;
        };
        @media (min-width: ${SM}px) and (max-width: ${MD}px) { 
            width: 100%;
            height: 608px;
            max-width: 100%;
        };
        @media (min-width: ${0}px) and (max-width: ${SM}px) { 
            width: 100%;
            height: 300px;
            max-width: 100%;
        };
    } ;



    .boxCard{
        position:absolute ;
        top:0;
        width:100%;
        height:100% ;
        display:flex ;
        flex-direction:column ;
        
    }
    
    
`











"use client";

import { THEME_COLORS } from '@theme/constants';
import { Carousel } from 'antd'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import CarouselItem from './CarouselItem'

const VitrinaList = ({ data }) => {
    const vitrinaRef = useRef();

    const [changing, setChanging] = useState(false);

    useEffect(() => {
        const next = () => {
            (vitrinaRef && vitrinaRef.current) && vitrinaRef.current.next();
            setChanging(!changing);
        }
        if (vitrinaRef && vitrinaRef.current) {
            setTimeout(() => next(), 10000000);
        }
    }, [changing]);

    return (
        <View>
            <Carousel ref={vitrinaRef} effect="scrollx" dotPosition="bottom">
                {data?.map(item => <CarouselItem key={item.id}{...item} />)}
            </Carousel>
        </View>

    );
}

export default VitrinaList;



const View = styled.div`
    border-bottom: 12pt solid ${THEME_COLORS.light.secondary.main};

    position: relative;
    .ant-carousel .slick-dots-bottom{
        margin-bottom: 2em;
    }
    .ant-carousel .slick-dots li button {
        background: ${THEME_COLORS.light.bg.darken};
        opacity: 0.4;
        height: 15px;
        width: 15px;
        border-radius: 50%;
    }
    .ant-carousel .slick-dots li.slick-active button {
        opacity: 1;
        background: ${THEME_COLORS.light.bg.darken};   
    }

`
